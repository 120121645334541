import config from "../config/config.json";
import routes from "../config/routes.json";

const getPageTitle = (title) => {
  if (title) return config.pageTitle.replace("{title}", title);
  else return config.defaultPageTitle;
};

const getUrl = (route, locale) => {
  if (routes[locale][route]) return routes[locale][route];
  else return route;
};

const isWindowAvailable = () => typeof window !== "undefined";

export { getPageTitle, getUrl, isWindowAvailable };
